import { trackingService as trackingServiceBase } from '@bluecodecom/tracking';

import { EventName, TrackingEvents } from './types';

class TrackingService {
  private static instance: TrackingService;

  private _provider = '';

  public set provider(value: string) {
    this._provider = value;
  }

  public static getInstance() {
    if (!TrackingService.instance) {
      TrackingService.instance = new TrackingService();
    }

    return TrackingService.instance;
  }

  public init(userId: string) {
    trackingServiceBase.init(process.env['NX_PUBLIC_MIXPANEL_TOKEN'], userId);
  }

  public trackEvent<T extends EventName>(
    event: T,
    ...params: TrackingEvents[T] extends null
      ? [undefined?]
      : [TrackingEvents[T]]
  ) {
    trackingServiceBase.trackEvent('PayAtPump: ' + event, {
      ...params?.[0],
      provider: this._provider,
      app_version: process.env['NX_PUBLIC_APP_VERSION'],
    });
  }
}

const trackingService = TrackingService.getInstance();

export default trackingService;
