import * as Sentry from '@sentry/react';

import { trackingService } from '@bluecodecom/pay-at-pump-webview/features/tracking';

import { Provider } from './service.types';

const CLAIM_TOKEN =
  new URLSearchParams(window.location.search).get('claim_token') || '';
const DATA = new URLSearchParams(window.location.search).get('data') || '';

class SessionService {
  private static instance: SessionService;

  private _sessionId = '';
  private _provider: Provider;

  public get sessionId() {
    return this._sessionId;
  }

  public set sessionId(value) {
    this._sessionId = value;
  }

  public get claimToken() {
    return CLAIM_TOKEN;
  }

  public get provider() {
    return this._provider;
  }

  public get data() {
    return DATA;
  }

  constructor() {
    try {
      const { provider } = JSON.parse(atob(DATA));

      this._provider = provider;
    } catch (err) {
      this._provider = 'NONE';

      trackingService.trackEvent('Invalid Provider In Data');
      Sentry.captureException(err);
    }
  }

  public static getInstance() {
    if (!SessionService.instance) {
      SessionService.instance = new SessionService();
    }

    return SessionService.instance;
  }
}

const sessionService = SessionService.getInstance();

export default sessionService;
